<template>
  <div>
    <b-container class="m-0 w-100" fluid>
      <header-slot> </header-slot>

      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: 'specialistdigital-process-cr-pending' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          PENDING
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'specialistdigital-process-cr-completed' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          COMPLETED
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'specialistdigital-process-cr-delete' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          DELETED
        </b-nav-item>
      </b-nav>
      <template class="border-top-primary border-3 border-table-radius px-0">
        <router-view :key="$route.name"></router-view>
      </template>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
